@tailwind base;
@tailwind components;
@tailwind utilities;



.admin table thead tr th {
    @apply bg-[#F8FAFC] text-dark-light text-[12px] px-2 py-1 text-left;
}

.admin table tbody tr {
    @apply text-dark-light text-left hover:bg-[#F8FAFC];
}

.admin table tbody tr td {
    @apply border-b border-gray-300 px-2 py-1 h-12;
} 

.admin{
    @apply text-sm overflow-x-hidden;
}
.admin h4{
    @apply font-bold text-xl text-slate-600;
}
.admin p{
    @apply text-slate-400;
} 