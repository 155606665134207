.border-line{
    @apply border rounded-md bg-white p-6;
}
.status-success1{
    @apply bg-green-600/10 text-[#4CB269] font-semibold border-2 bg-[#90DF41] bg-opacity-20 border-[#4CB269] flex gap-1 py-1 text-xs px-2 rounded-full items-center;
}
.status-error1{
    @apply bg-green-600/10 text-[#fe5f5f] font-semibold border-2 bg-[#ff6b66] bg-opacity-20 border-[#ed5c5c] flex gap-1 py-1 text-xs px-2 rounded-full items-center;
}

.status-pending{
    @apply bg-green-600/10 text-[#ff8e24e4] font-semibold border-2 bg-[#fec95f] bg-opacity-20 border-[#ffb050e4] flex gap-1 py-1 text-xs px-2 rounded-full items-center;
}
#pro{
    @apply text-black-900;
}
.input-radio{
    @apply block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-white peer-checked:text-black-900 font-semibold ;
}
.dateSection{
    @apply flex flex-col space-x-1 items-center justify-center px-5 py-2 bg-gray-100 rounded-md;
}
.dateSection span:first-child{
    @apply text-sm font-semibold;
}
.dateSection span:last-child{
    @apply text-lg font-bold;
}
.rdv-card{
@apply border rounded-md bg-white  p-2;
}
.table1{
    @apply w-full;
}
.table1 thead{
    @apply bg-primary text-white;
}
.table1 tbody tr{
    @apply border-b;
}
.table1 tbody tr td:first-child{
    @apply px-3 py-2 text-sm text-gray-1 font-semibold;
}
.table1 tbody tr td:last-child{
    @apply px-3 py-2 text-sm font-semibold;
}
.info-col div{
    @apply flex-col justify-center items-center;
}
.info-col div p:first-child{
    @apply text-sm text-slate-600;
}
.info-col div p:last-child{
    @apply text-sm text-black-900 font-medium;
}

.table2{
    @apply w-full;
}

.table2{
    @apply w-full text-start;
}
.table2 thead th{
    @apply text-start px-3 border-b py-3 text-gray-1 font-semibold text-sm;
}
.table2 tbody tr td{
    @apply text-start px-3 border-b py-3 text-black-900 font-semibold text-sm;
}
.title1{
    @apply text-lg font-semibold;
}
.title2{
    @apply text-sm font-medium;
}

.status-success{
    @apply bg-green-600/10 text-green-700 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
  }
.status-success div{
    @apply bg-green-500;
}
  
  .status-error{
    @apply bg-red-600/10 text-red-500 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
  }
  .status-error div{
    @apply bg-red-500;
  }
  .input1{
    @apply border border-gray-300 rounded-md px-3 py-2 text-sm  focus:outline focus:outline-2 focus:outline-blue-500/20 w-20;
}
.input2{
    @apply border border-gray-300 rounded-md px-3 py-2 text-sm  focus:outline focus:outline-2 focus:outline-blue-500/20;
}

 #back1{
background-image: url('../../website/assets/images/pexels-pixabay-206359.jpg');
 }
 #back2{
  background-image: url('../../website/assets/images/pexels-griffinw-1649190.jpg');
}

.loader {
    border-top-color: #ff8000;
    /* -webkit-animation: load 1.5s linear infinite;
    animation: spinner 1.5s linear infinite; */
  }
  
  /* @-webkit-keyframes load {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes load {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */
  
