/* Testimonials.css */
.slider {
    width: 100%;
    height: var(--height);
    overflow: hidden;
    mask-image: linear-gradient(
        to right,
        transparent,
        #000 10% 90%,
        transparent
    );
}

.slider .list {
    display: flex;
    width: 100%;
    min-width: calc(var(--width) * var(--quantity));
    position: relative;
}

.slider .list .item {
    width: var(--width);
    height: var(--height);
    position: absolute;
    left: 100%;
    animation: autoRun 40s linear infinite;
    transition: filter 0.5s;
    animation-delay: calc((40s / var(--quantity)) * (var(--position) - 1)) !important;
    margin: 0 0.75rem; /* Marges gauche et droite pour l'espacement */
    cursor: pointer;
}

.slider .list .item .testimonial-card {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: start;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre pour les cartes */
    /* overflow: hidden; */
    position: relative;
}

.slider .list .item .testimonial-image {
    width: 100px; /* Largeur fixe de l'image */
    height: 100px; /* Hauteur pleine */
    position: absolute; /* Position absolue pour l'image */
    left: -40px; /* Positionnement à gauche */

}

.slider .list .item .testimonial-details {
    margin-left: 100px; /* Marge pour laisser de l'espace pour l'image */
    width: calc(var(--width) - 150px); /* Largeur restante après l'image */
    padding: 1rem;
    box-sizing: border-box;
}

.slider .list .item .name {
    font-weight: bold;
}

.slider .list .item .job-title {
    color: gray;
    margin-bottom: 0.5rem;
}

.slider .list .item .message {
    font-style: italic;
}

@keyframes autoRun {
    from {
        left: 100%;
    }
    to {
        left: calc(var(--width) * -1);
    }
}

.slider:hover .item {
    animation-play-state: paused !important;
    filter: grayscale(1);
}

.slider .item:hover {
    filter: grayscale(0);
}

.slider[reverse="true"] .item {
    animation: reversePlay 40s linear infinite;
}

@keyframes reversePlay {
    from {
        left: calc(var(--width) * -1);
    }
    to {
        left: 100%;
    }
}
